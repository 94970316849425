<template>
  <div class="app" id="auth">
    <div class="container text-center mt-5 pt-4">
      <div class="mt-5" v-if="loading">
        <loader />
      </div>
      <div v-else class="card card-container pt-3" style="max-width: 800px">
        <h4 class="font-weight-bold">{{ 'settings.deleteAccount' | translate }}</h4>
        <br />
        <p class="text-justify">{{ 'settings.deleteAccountText' | translate }}</p>
        <div class="text-right">
          <button class="btn btn-danger mt-2 mb-1" @click="removeAccount()">
            {{ 'settings.removeMyData' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    };
  },
  methods: {
    removeAccount() {
      this.$prompt(this.$t('form.enterCurrentPasswordText'), {
        confirmButtonText: this.$t('general.save'),
        cancelButtonText: this.$t('general.cancel'),
        inputPlaceholder: this.$t('form.currentPasswordPlaceholder'),
        inputType: 'password'
      }).then(({ value }) => {
        this.loading = true;
        this.$http
          .post('/profile/delete', { password: value })
          .then(() => {
            this.$notify({
              type: 'success',
              message: this.$t('notifications.accountDeleted'),
              position: 'bottom-right'
            });
            this.loading = false;
            this.$store.dispatch('auth/logout');
            this.$router.push('/auth/login');
          })
          .catch((err) => {
            if (err && err.error) {
              this.$notify({
                type: 'error',
                message: err.error,
                position: 'bottom-right'
              });
            } else {
              this.$notify({
                type: 'error',
                message: this.$t('notifications.error'),
                position: 'bottom-right'
              });
            }
            this.loading = false;
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../auth/auth.scss';

button {
  cursor: pointer !important;
}
</style>
